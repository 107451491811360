/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.carousel.min.css
 * - /npm/owl.carousel@2.3.4/dist/assets/owl.theme.default.min.css
 * - /npm/select2@4.0.6-rc.1/dist/css/select2.min.css
 * - /npm/animate.css@3.7.0/animate.min.css
 * - /npm/slick-carousel@1.8.1/slick/slick.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
